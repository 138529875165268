import { CSSProperties, Component, ReactNode } from "react";
import 'typeface-jura';

interface NavaBarProps {
    closeNavBar(): void
}

export class NavBar extends Component<NavaBarProps> {
    containerStyle: CSSProperties = {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 10010,
        backgroundColor: "rgba(0, 0, 0, 0.3)"
    }
    navigatorStyle: CSSProperties = {
        height: "100%",
        width: 300,
        backgroundColor: "white"

    }
    render(): ReactNode {
        return (
            <div style={this.containerStyle} onClick={() => this.props.closeNavBar()}>
                <div style={this.navigatorStyle} onClick={(event) => {
                    event.stopPropagation();
                }}>
                    <div style={{ fontFamily: 'Jura, sans-serif', fontSize: 30 }}>
                        SafeXplorer
                    </div>
                    <div style={{width: "calc(95% - 20px)", height: 1, backgroundColor: "grey", margin: 20}}>
			<p style={{fontFamily: 'Jura, sans-serif', paddingTop: 20, textAlign: "left"}}>
                            SafeXplorer is a user-friendly mapping app designed to make navigation safer by integrating real-time crime reports and safety features. 
                            <br></br>
                            <br></br>
                            With SafeXplorer, you can explore routes confidently by viewing safety scores, avoiding high-risk areas, and staying updated on local incidents. It’s your go-to tool for safer, smarter travel.
                            <br></br>
                            <br></br>
                            Tribute to:
                            <br></br>
                            Phat Dang (<a href="https://github.com/alexeipc">@alexeipc</a>)
                            <br></br>
                            Quoc Nguyen (<a href="https://github.com/hiiragi1604">@hiiragi1604</a>)
                            <br></br>
                            Tu Pham (<a href="https://github.com/StewAway">@StewAway</a>)
                            <br></br>
                            Toan Le (<a href="https://github.com/LeCaToX">@LeCaTox</a>)
                        </p>
		    </div>
                </div>
            </div>
        )
    }
}
